import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "home" },
    component: () => import("../views/home-page.vue")
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { layout: "auth" },
    component: () => import("../views/auth-page.vue")
  },
  {
    path: "/forget",
    name: "Forget",
    meta: { layout: "auth" },
    component: () => import("../views/forget-page.vue")
  },
  {
    path: "/redirect",
    name: "Redirect",
    meta: { layout: "auth" },
    component: () => import("../views/redirect-page.vue")
  },
  {
    path: "/*",
    name: "404",
    component: () => import("../views/404-page.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/auth", "/forget", "/redirect"],
    authRequired = !publicPages.includes(to.path),
    loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    return next("/auth");
  }

  next();
});

export default router;
