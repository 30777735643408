import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
// import tokenModule from "./modules/token.js";

Vue.use(Vuex);

const tokenLocal = localStorage.getItem("token"),
  refreshTokenLocal = localStorage.getItem("refreshToken");

export default new Vuex.Store({
  state: {
    mounted: true,
    user: {
      isIp: true,
      id: "",
      firstName: "",
      lastName: "",
      photo: "",
      autoPark: null,
      autoParkName: "",
      isPartner: false,
      showOrderPrice: false
    },
    token: tokenLocal ? tokenLocal : "",
    refreshToken: refreshTokenLocal ? refreshTokenLocal : ""
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
      localStorage.setItem("token", value);
    },
    SET_TOKEN_REFRESH(state, value) {
      state.refreshToken = value;
      localStorage.setItem("refreshToken", value);
    },
    SET_MOUNTED(state, value, autoPark) {
      if (!value && !autoPark) {
        state.mounted = false;
      } else {
        state.mounted = true;
      }
    },
    SET_REMOVE_TOKENS(state) {
      state.token = "";
      localStorage.removeItem("token");
      state.refreshToken = "";
      localStorage.removeItem("refreshToken");
    },
    SET_USER_DATA(state, value) {
      const {
        id,
        isIp,
        firstName,
        lastName,
        photo,
        autoPark,
        isPartner,
        autoParkNameOrg,
        showOrderPrice
      } = value.data.response;
      state.user.isIp = isIp;
      state.user.isPartner = isPartner;
      state.user.id = id;
      state.user.firstName = firstName;
      state.user.lastName = lastName;
      state.user.photo = photo;
      state.user.autoPark = autoPark;
      state.user.autoParkName = autoParkNameOrg;
      state.user.showOrderPrice = showOrderPrice;
    }
  },
  actions: {
    GET_TOKEN({ commit }, value) {
      commit("SET_TOKEN", value);
    },
    GET_TOKEN_REFRESH({ commit }, value) {
      commit("SET_TOKEN_REFRESH", value);
    },
    REMOVE_TOKENS({ commit }) {
      commit("SET_REMOVE_TOKENS");
    },
    GET_USER_DATA({ commit }) {
      return axios
        .get("/api/driver/info/get", {
          headers: { Authorization: `Bearer ${this.state.token}` }
        })
        .then((res) => {
          commit("SET_USER_DATA", res);
          commit("SET_MOUNTED", (this.state.user.isIp || this.state.user.isPartner), this.state.user.autoPark);
          window.dataLayer?.push({
            event: "gtagApiGet",
            gtagApiResult: {
              client_id: res.data.response.id
            }
          });
        })
        .catch(() => {
          location.href = "/auth";
        });
    }
  },
  modules: {
    // token: tokenModule
  }
});
