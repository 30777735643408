<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import AuthLayout from "./layouts/auth-layout.vue";
import HomeLayout from "./layouts/home-layout.vue";

export default {
  name: "App",
  components: {
    AuthLayout,
    HomeLayout
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "auth") + "-layout";
    }
  }
};
</script>

<style lang="scss"></style>
